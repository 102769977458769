<template>
    <b-container fluid>
        <!-- <iq-card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('external_sidebar.trainee_bill_receipt') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="fiscal_year_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.fiscal_year')}}
                        </template>
                        <v-select name="organization"
                          v-model="search.fiscal_year_id"
                          label="text"
                          :reduce="item => item.value"
                          :options= fiscalYearList
                          :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          :label="$t('elearning_config.organization')"
                          label-for="organization"
                      >
                        <v-select name="organization"
                            disabled
                            v-model="search.org_id"
                            label="text"
                            :reduce="item => item.value"
                            :options= orgList
                            :placeholder="$t('globalTrans.select')"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_type_id"
                      >
                        <template v-slot:label>
                          {{$t('elearning_config.training_type')}}
                        </template>
                        <b-form-select
                          plain
                          v-model="search.training_type_id"
                          :options="trainingTypeList"
                          id="training_type_id"
                          >
                          <template v-slot:first>
                              <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                          </template>
                          </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_category_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_category')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_category_id"
                            :options="trainingCategoryList"
                            id="training_category_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="6" sm="12">
                      <b-form-group
                          class="row"
                          label-cols-sm="4"
                          label-for="training_title_id"
                        >
                          <template v-slot:label>
                            {{$t('elearning_config.training_title')}}
                          </template>
                          <b-form-select
                            plain
                            v-model="search.training_title_id"
                            :options="trainingTitleList"
                            id="training_title_id"
                            >
                            <template v-slot:first>
                                <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                            </b-form-select>
                      </b-form-group>
                    </b-col>
                    <b-col lg="12" sm="12" class="text-right">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card> -->
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('external_sidebar.trainee_bill_receipt') }} {{ $t('globalTrans.list') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(total_rent)="data">
                                          {{ $n(data.item.service_charge) }}
                                        </template>
                                        <template v-slot:cell(floor_no)="data">
                                          {{ $n(data.item.floor_no) }}
                                        </template>
                                        <template v-slot:cell(room_no)="data">
                                          {{ $n(data.item.room_no) }}
                                        </template>
                                        <template v-slot:cell(type_name)="data">
                                        <span class="capitalize">{{ data.item.type_name }}</span>
                                        </template>
                                        <template v-slot:cell(mobile)="data">
                                          {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(start_date)="data">
                                          <span class="capitalize">{{ data.item.start_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(end_date)="data">
                                          <span class="capitalize">{{ data.item.end_date | dateFormat }}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                           <a href="javascript:" class="btn_table_action table_action_status" title="Receipt" v-b-modal.modal-6 v-if="data.item.payment_status == 2" @click="edit(data.item),deli(data.item)">
                                                <i class="fas fa-receipt"></i>
                                            </a>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <!-- <b-modal id="modal-4" size="lg" :title="$t('external_sidebar.trainee_bill_receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
              <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
                {{  $t('globalTrans.export_pdf') }}
              </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
        </b-modal> -->
        <b-modal id="modal-6" size="lg" :title="$t('elearning_venue.receipt')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <!-- <b-button @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button> -->
          <Receipt :id="editItemId" :key="editItemId" ref="details"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { billPaymentReceipt } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Receipt from './Receipt'
import { mapGetters } from 'vuex'
import ExportPdf from './export_pdf_details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Receipt
    },
    data () {
        return {
          search: {
            org_id: this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg,
            fiscal_year_id: 0,
            user_type: this.$store.state.ExternalUserIrrigation.panelObj.trainingType
          },
          org_id: this.$store.state.ExternalUserTraining.trainerOrTrainee.professional_info.org_id,
          trainingCategoryList: [],
          trainingTitleList: [],
          itemidd: 0
        }
    },
    computed: {
      ...mapGetters({
        authUser: 'Auth/authUser'
      }),
      trainingTypeList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      orgList: function () {
        if (this.$store.state.ExternalUserTraining.trainerOrTrainee) {
          // return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0 && (item.value === 12 || item.value === this.$store.state.ExternalUserTraining.trainerOrTrainee.professional_info.org_id))
          return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === this.$store.state.ExternalUserIrrigation.panelObj.trainingOrg)
        } else {
          return this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.filter(item => item.status === 0 && item.value === 12)
        }
      },
      trainerEvaluationList: function () {
        return this.$store.state.ExternalUserTraining.traineeTranerObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
              { label: this.$t('globalTrans.fiscal_year'), class: 'text-left' },
              { label: this.$t('elearning_config.organization'), class: 'text-left' },
              { label: this.$t('globalTrans.name'), class: 'text-left' },
              { label: this.$t('globalTrans.mobile'), class: 'text-left' },
              { label: this.$t('elearning_config.room_type'), class: 'text-left' },
              { label: this.$t('elearning_config.floor_no'), class: 'text-left' },
              { label: this.$t('elearning_config.room_no'), class: 'text-left' },
              { label: this.$t('globalTrans.start_date'), class: 'text-left' },
              { label: this.$t('globalTrans.end_date'), class: 'text-left' },
              { label: this.$t('elearning_accommodation.service_charge'), class: 'text-left' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year_bn' },
              { key: 'org_bn' },
              { key: 'name_bn' },
              { key: 'mobile' },
              { key: 'room_type_bn' },
              { key: 'floor_no' },
              { key: 'room_no' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_rent' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'fiscal_year' },
              { key: 'org' },
              { key: 'name' },
              { key: 'mobile' },
              { key: 'room_type' },
              { key: 'floor_no' },
              { key: 'room_no' },
              { key: 'start_date' },
              { key: 'end_date' },
              { key: 'total_rent' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
    },
    async created () {
      this.loadData()
    },
    mounted () {
        core.index()
    },
    watch: {
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      deli (id) {
        this.itemidd = id.id
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
          this.loadData()
      },
      async searchData () {
          this.loadData()
      },
      // loadData () {
      //   RestApi.getData(trainingElearningServiceBaseUrl, billPaymentReceipt, this.search).then(response => {
      //     if (response.success) {
      //       this.$store.dispatch('setList', this.getCustomDataList(response.data))
      //       this.paginationData(response.data)
      //     }
      //     this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      //   })
      // },
      // getCustomDataList (data) {
      //   const divisionList = this.$store.state.commonObj.divisionList
      //   const districtList = this.$store.state.commonObj.districtList
      //   const upazilaList = this.$store.state.commonObj.upazilaList
      //   const unionList = this.$store.state.commonObj.unionList
      //   const listData = data.map(item => {
      //     const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
      //     const orgData = {}
      //     if (typeof orgObj !== 'undefined') {
      //       orgData.org = orgObj.text_en
      //       orgData.org_bn = orgObj.text_bn
      //     } else {
      //       orgData.org = ''
      //       orgData.org_bn = ''
      //     }

      //     const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
      //     const officeData = {}
      //     if (typeof officeObj !== 'undefined') {
      //       officeData.office = officeObj.text_en
      //       officeData.office_bn = officeObj.text_bn
      //     } else {
      //       officeData.office = ''
      //       officeData.office_bn = ''
      //     }

      //     const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
      //     const fiscalYearData = {}
      //     if (typeof fiscalYearObj !== 'undefined') {
      //       fiscalYearData.fiscal_year = fiscalYearObj.text_en
      //       fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
      //     } else {
      //       fiscalYearData.fiscal_year = ''
      //       fiscalYearData.fiscal_year_bn = ''
      //     }
      //     const trainingTitleObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
      //     const trainingTitleData = {}
      //     if (typeof trainingTitleObj !== 'undefined') {
      //       trainingTitleData.training_title = trainingTitleObj.text_en
      //       trainingTitleData.training_title_bn = trainingTitleObj.text_bn
      //     } else {
      //       trainingTitleData.training_title = ''
      //       trainingTitleData.training_title_bn = ''
      //     }
      //     const OfficeTypeListObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
      //     const OfficeTypeData = {}
      //     if (typeof OfficeTypeListObj !== 'undefined') {
      //       OfficeTypeData.office_type = OfficeTypeListObj.text_en
      //       OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
      //     } else {
      //       OfficeTypeData.office_type = ''
      //       OfficeTypeData.office_type_bn = ''
      //     }
      //     const trainingTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
      //     const trainingTypeData = {}
      //     if (typeof trainingTypeObj !== 'undefined') {
      //       trainingTypeData.training_type = trainingTypeObj.text_en
      //       trainingTypeData.training_type_bn = trainingTypeObj.text_bn
      //     } else {
      //       trainingTypeData.training_type = ''
      //       trainingTypeData.training_type_bn = ''
      //     }
      //     const trainingCategoryObj = this.$store.state.ExternalUserTraining.traineeTranerObj.trainingCategoryList.find(doc => doc.value === parseInt(item.training_category_id))
      //     const trainingCategoryData = {}
      //     if (typeof trainingCategoryObj !== 'undefined') {
      //       trainingCategoryData.training_category = trainingCategoryObj.text_en
      //       trainingCategoryData.training_category_bn = trainingCategoryObj.text_bn
      //     } else {
      //       trainingCategoryData.training_category = ''
      //       trainingCategoryData.training_category_bn = ''
      //     }
      //     const desigObj = this.$store.state.ExternalUserIrrigation.commonObj.designationList.find(designation => designation.value === parseInt(item.personal.professionalInfo.designation_id))
      //     const desigData = {}
      //     if (typeof desigObj !== 'undefined') {
      //       desigData.designation_name = desigObj.text_en
      //       desigData.designation_name_bn = desigObj.text_bn
      //     } else {
      //       desigData.designation_name = ''
      //       desigData.designation_name_bn = ''
      //     }
      //     const divisionObject = divisionList.find(division => division.value === item.pre_division_id)
      //     const districtObject = districtList.find(district => district.value === item.pre_district_id)
      //     const upazilaObject = upazilaList.find(upazilla => upazilla.value === item.pre_upazilla_id)
      //     const unionObject = unionList.find(union => union.value === item.pre_union_id)
      //     const divisionData = {
      //         division_name: typeof divisionObject !== 'undefined' ? divisionObject.text_en : '',
      //         division_name_bn: typeof divisionObject !== 'undefined' ? divisionObject.text_bn : ''
      //     }
      //     const districtData = {
      //       district_name: typeof districtObject !== 'undefined' ? districtObject.text_en : '',
      //       district_name_bn: typeof districtObject !== 'undefined' ? districtObject.text_bn : ''
      //     }
      //     const upazillaData = {
      //       upazilla_name: typeof upazilaObject !== 'undefined' ? upazilaObject.text_en : '',
      //       upazilla_name_bn: typeof upazilaObject !== 'undefined' ? upazilaObject.text_bn : ''
      //     }
      //     const unionData = {
      //       union_name: typeof unionObject !== 'undefined' ? unionObject.text_en : '',
      //       union_name_bn: typeof unionObject !== 'undefined' ? unionObject.text_bn : ''
      //     }
      //     const address = divisionData.division_name + ',' + districtData.district_name + ',' + upazillaData.upazilla_name + ',' + unionData.union_name
      //     const addressData = {
      //       address: address
      //     }
      //     return Object.assign({}, item, orgData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData, trainingCategoryData, desigData, addressData)
      //   })
      //   return listData
      // },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, billPaymentReceipt, params).then(response => {
            if (response.success) {
              this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
              this.paginationData(response.data)
            }
            this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
        },
        getCustomDataList (data) {
          const listData = data.map(item => {
            const orgObj = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(doc => doc.value === parseInt(item.org_id))
            const orgData = {}
            if (typeof orgObj !== 'undefined') {
              orgData.org = orgObj.text_en
              orgData.org_bn = orgObj.text_bn
            } else {
              orgData.org = ''
              orgData.org_bn = ''
            }

            const fiscalYearObj = this.$store.state.ExternalUserIrrigation.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
            const fiscalYearData = {}
            if (typeof fiscalYearObj !== 'undefined') {
              fiscalYearData.fiscal_year = fiscalYearObj.text_en
              fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
            } else {
              fiscalYearData.fiscal_year = ''
              fiscalYearData.fiscal_year_bn = ''
            }
            const officeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
            const officeData = {}
            if (typeof officeObj !== 'undefined') {
              officeData.office = officeObj.text_en
              officeData.office_bn = officeObj.text_bn
            } else {
              officeData.office = ''
              officeData.office_bn = ''
            }
            const officeTypeObj = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(doc => doc.value === parseInt(item.office_type_id))
            const officeTypeData = {}
            if (typeof officeTypeObj !== 'undefined') {
              officeTypeData.office_type = officeTypeObj.text_en
              officeTypeData.office_type_bn = officeTypeObj.text_bn
            } else {
              officeTypeData.office_type = ''
              officeTypeData.office_type_bn = ''
            }
            const roomTypeObj = this.$store.state.ExternalUserTraining.traineeTranerObj.roomTypeList.find(roomType => roomType.value === parseInt(item.room_type_id))
            const roomTypeData = {}
            if (typeof roomTypeObj !== 'undefined') {
              roomTypeData.room_type = roomTypeObj.text_en
              roomTypeData.room_type_bn = roomTypeObj.text_bn
            } else {
              roomTypeData.room_type = ''
              roomTypeData.room_type_bn = ''
            }
            const roomObj = this.$store.state.ExternalUserTraining.traineeTranerObj.roomList.find(roomList => parseInt(roomList.value) === parseInt(item.room_id))
            const roomData = {}
            if (typeof roomObj !== 'undefined') {
              roomData.room_no = roomObj.text_en
            } else {
              roomData.room_no = ''
            }
            return Object.assign({}, item, orgData, fiscalYearData, roomData, officeTypeData, officeData, roomTypeData)
          })
          return listData
        },
      pdfExport () {
        const tmpData = this.$store.state.list.find(item => item.id === this.itemidd)
        const acco = JSON.parse(JSON.stringify(tmpData))
        const reportTitle = this.$i18n.locale === 'en' ? 'Circular Publication' : 'বিজ্ঞপ্তি প্রকাশ'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, acco)
      }
    }
}
</script>
